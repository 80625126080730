import { graphql } from 'gatsby';
import * as React from 'react';
import AppTemplate from '../components/AppTemplate';
import Form from '../components/ContactSections/Form';

// markup
function IndexPage({ data, location }: any) {
  return (
    <AppTemplate
      navigation={data.contentfulNavigation}
      SEO={{
        title: 'Pulpetti - Helppokäyttöinen oppimisen verkkotyöpöytä',
        description: 'Pulpetti on helppokäyttöinen, nopea ja nykyaikainen oppimisen digitaalinen verkkotyöpöytä nopeuttamaan opetusta helposti pilvipalveluna.',
        author: 'Ritta.fi',
        pathname: '/pulpetti',
      }}
    >
      <Form location={location} />
    </AppTemplate>
  );
}

export const query = graphql`
  query contactQuery($locale: String) {
    contentfulLandingPage(
      contentful_id: {eq: "6QiJ1HJLc4ZE7CHGDvaZeF"}
      node_locale: { eq: $locale }
    ) {
      heroTitle {
        raw
      }
      heroSubtitle {
        raw
      }
      heroImage {
        file {
  
        url}
      }
    }

    contentfulNavigation(
      contentful_id: {eq: "39n107RjyAszi5xLee3gMS"}
      node_locale: {eq: $locale}
    ) {
      title
      navigationOptions {
        ... on ContentfulNavigationLink {
          title
          url
          __typename
        }
 
      }
      loginText
    }
  }
`;

export default IndexPage;
