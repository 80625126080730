import {
  Container,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { Link as GatsbyLink, Link } from 'gatsby';
import { useState } from 'react';

export default function Form({ location }: any) {
  const [thanks, setThanks] = useState(false);
  return (
    <Container bg="gray.50" maxW="full" mt={0} centerContent overflow="hidden">
      <Box
        bg="white"
        color="black"
        borderRadius="lg"
        shadow={{
          md: 'xl',
        }}
        m={{ base: 0, md: 16, lg: 10 }}
        p={{ base: 0, md: 5, lg: 16 }}
        w={{ base: '100%', md: '60%' }}
      >
        <Box p={4}>
          <Box>
            <Heading>Ota yhteyttä</Heading>
            <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.900">
              Täytä yhteydenottolomake tai lähetä sähköpostia:
            </Text>
            <Box py={{
              base: 5, sm: 5, md: 5, lg: 5,
            }}
            >
              <VStack pl={0} spacing={3} alignItems="flex-start">
                <Button
                  as="a"
                  href="mailto:roni@ritta.fi"
                  size="md"
                  height="48px"
                  width="150px"
                  variant="ghost"
                  color="black"
                  _hover={{ border: '2px solid #1C6FEB' }}
                  leftIcon={(
                    <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24" height="20">
                      <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
                    </svg>
                        )}
                >
                  roni@ritta.fi
                </Button>
              </VStack>
            </Box>
          </Box>
          <Box bg="white" borderRadius="lg">
            <Box m={8} color="#0B0E3F">
              {thanks ? (
                <p>
                  Kiitos yhteydenotosta!
                  Pyrimme vastaamaan sinulle mahdollisimman pian.
                </p>
              ) : (
                <form onSubmit={(event: any) => {
                  event.preventDefault();
                  fetch('https://formspree.io/f/xpznwykd', {
                    headers: {
                      'content-type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({
                      name: event.target.name.value,
                      topic: event.target.topic.value,
                      email: event.target.email.value,
                      organization: event.target.organization.value,
                      //  phone: event.target.phone.value,
                      message: event.target.message.value,
                      ping: '<@250662753541423115>',
                    }),
                    redirect: 'manual',
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'omit',
                  }).then(() => {
                    setThanks(true);
                  });
                }}
                >
                  <VStack spacing={5}>
                    <FormControl id="name" isRequired>
                      <FormLabel>Nimi</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <Input type="text" size="md" required name="name" />
                      </InputGroup>
                    </FormControl>
                    <FormControl id="email" isRequired>
                      <FormLabel>Sähköpostiosoite</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <Input type="email" size="md" required name="email" />
                      </InputGroup>
                    </FormControl>
                    <FormControl id="organization">
                      <FormLabel>Kunta/oppilaitos</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <Input type="text" size="md" name="organization" />
                      </InputGroup>
                    </FormControl>
                    {/* <FormControl id="phone">
                      <FormLabel>Puhelinnumero</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <Input type="tel" size="md" name="phone" />
                      </InputGroup>
              </FormControl> */}
                    <FormControl id="topic">
                      <FormLabel>Mitä asiasi koskee?</FormLabel>
                      <RadioGroup defaultValue={new URLSearchParams(location.search).has('topic') ? (new URLSearchParams(location.search).get('topic') || 'other') : 'other'} name="topic">
                        <Stack>
                          {/* <Radio value="ritta-system">
                            Ritta-oppilashallintojärjestelmä
                          </Radio> */}
                          <Radio value="pulpetti">Pulpetti</Radio>
                          <Radio value="other">Jokin muu</Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                    <FormControl id="message" isRequired>
                      <FormLabel>Viestisi</FormLabel>
                      <Textarea
                        borderColor="gray.300"
                        _hover={{
                          borderRadius: 'gray.300',
                        }}
                        placeholder="Viestisi"
                        name="message"
                        required
                      />
                    </FormControl>
                    <Link
                      as={GatsbyLink}
                      to="/privacy"
                    >
                      Lähettämällä hyväksyt tietojesi käsittelyn tietosuojaselosteen mukaisesti.
                    </Link>
                    <FormControl id="submit" float="right">
                      <Button
                        variant="solid"
                        colorScheme="accesibleButton"
                        color="white"
                        type="submit"
                      >
                        Lähetä
                      </Button>
                    </FormControl>
                  </VStack>
                </form>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
